/* ====== KEYFRAMES ====== */

@keyframes triangleAppear {
  from {
    opacity: 0%;
    display: none;}
  to {
    display: flex;
    opacity: 100%;}
}

@keyframes triangleDisappear {
  from {
    opacity: 100%;
    display: flex}
  to {
    opacity: 0%;
    display: none;}
}

@keyframes closeRightMenu {
  from {right: 0px;}
  to {right: -210px;}
}

@keyframes openRightMenu {
  from {right: -210px;}
  to {right: 0px;}
}

@keyframes openLeftMenu {
  from {left: -210px;}
  to {left: 0px;}
}

@keyframes closeLeftMenu {
  from {left: 0px;}
  to {left: -210px;}
}

@keyframes portfolio {
  0% {
    background-color: var(--light-blue);
    opacity: 70%;
  }
  25% {
    background-color: var(--bright-red);
  }
  100%{
    opacity: 0%;
  }
}

@keyframes portfolioOut {
  0% {
    opacity: 0%;
  }
  100%{
    background-color: var(--light-blue);
    opacity: 70%;
  }
}

/* ====== ANIMATIONS ====== */

.open-right-menu {
  animation: openRightMenu 1s forwards;
}

.close-right-menu {
  animation: closeRightMenu 1s forwards;
}

.open-left-menu {
  animation: openLeftMenu 1s forwards;
}

.close-left-menu {
  animation: closeLeftMenu 1s forwards;
}

.left-menu-triangle-close-animation {
  animation: triangleAppear 1s forwards;
}

.left-menu-triangle-open-animation {
  animation: triangleDisappear 1s forwards;
}

.right-menu-triangle-close-animation {
  animation: triangleAppear 1s forwards;
}

.right-menu-triangle-open-animation {
  animation: triangleDisappear 1s forwards;
}

/* ====== COLORS ====== */

:root {
	--deep-red: #9e2d21;
  --bright-red: #c52126;
  --bright-red-op: rgba(197, 33, 39, 0.80);
  --vermillion: #ff0008;
  --light-blue: #92e3f6;
  --white: #FFFFFF;
}

/* === BACKGROUNDS === */

.black {
  background-color: black;
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-white {
  background-color: var(--white);
}

.bg-bright-red {
  background-color: var(--bright-red);
}

.bg-vermillion {
  background-color: var(--vermillion);
}

.bg-center {
  background-position: center;
}

.light-blue {
  color: var(--light-blue);
}

/* === COLORS === */

.bright-red {
  color: var(--bright-red)
}

.white {
  color: var(--white);
}

.vermillion {
  color: var(--vermillion)
}
/* === BORDERS === */

.border-radius {
  border-radius: 20px;
}

.red-border {
  border: 1px solid var(--bright-red);
}

.red-border-bottom {
  border-bottom: 1px solid var(--bright-red);
}

.red-border-left {
  border-left: 1px solid var(--bright-red);
}

.red-border-right {
  border-right: 1px solid var(--bright-red);
}

.white-border {
  border: 1px solid var(--white);
}

.white-border-right {
  border-right: 1px solid var(--white);
}

.border-right {
  border-right: 2px solid var(--vermillion);
}

.vermillion-border-left {
  border-left: 1px solid var(--vermillion);
}

/* ====== FONTS ====== */

p {
	font-family: 'futura', sans-serif;
	font-size: 1rem;
	color: var(--bright-red);
	font-style: normal;
	line-height: 1.3;
}

ul {
  list-style-type: none;
  padding-left: 0vw !important;
  font-family: 'futura', sans-serif;
	font-size: 1rem;
}

.circles {
  list-style-type: circle ;
  padding-left: 2vw !important;
}

h1 {
	font-family: 'nasalization', sans-serif;
  font-size: 4em;
}

h2 {
	font-family: 'futura-pt', sans-serif;
	font-style: normal;
	font-weight: 600;
	padding: 0;
	margin: 0;
	color: rgb(197, 33, 38);
	font-size: 1.7rem;
	line-height: 1.3;
}

h3 {
	font-family: 'nasalization', sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}

a:link {
  text-decoration: none !important;
}

a:hover {
  color: var(--light-blue)
}

input {
  background-color: transparent;
  border-radius: 5px;
  border-bottom: 2px solid white;
  border-top: none;
  border-right: none;
  border-left: none;
  color: var(--light-blue);
  font-family: 'nasalization', sans-serif;
  margin: 20px 10px;
  font-family: 'nasalization', sans-serif;
}

input:focus {
  outline: none;
  border-bottom: 2px solid var(--light-blue);
}

input::placeholder {
  font-family: 'nasalization', sans-serif;
  color: white;
}

input[type='submit'] {
  background-color: white;
  color: var(--vermillion);
  padding: 10px 20px;
  text-transform: uppercase;
  border: 2px solid white;
  transition-duration: .5s;
}

input[type='submit']:hover {
  background-color: var(--light-blue);
  color: var(--vermillion);
  padding: 10px 20px;
  text-transform: uppercase;
  transition-duration: .5s;
}

.nasalization {
  font-family: 'nasalization', sans-serif;
}

.sub-title {
  font-size: 1.5em;
  top: 20px;
}

.sub-title-right {
  font-size: 1.5em;
  top: 20px;
  right: 20px;
}

.portfolio-title {
  font-size: 1.5em;
  width: 75%;
}

.writing-title {
  font-size: 2em;
  padding: 2vH 1vW 2vH 0vW;
}

.writing-details {
  padding: 2vH 1vW 0vH 1vW;
}
.opacity-80 {
  opacity: 80%;
}

.uppercase {
  text-transform: uppercase;
  font-weight: 600 !important;
}

.all-caps {
  text-transform: uppercase;
}

.cursor {
  cursor: pointer;
}

/* === ALIGNMENT === */

.text-al-center {
  text-align: center;
}

.txt-al-right {
  text-align: right;
}

.txt-al-left {
  text-align: left;
}

/* ====== SPACING ====== */

body {
  margin: 0px;
  padding: 0px;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.z-index {
  z-index: 999 !important;
}

.z-index-100 {
  z-index: 100 !important;

}
/* === FLEX === */

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.just-center {
  justify-content: center;
}

.ac-center {
  align-content: center;
}

.al-center {
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.ai-flex-end {
  align-items: flex-end;
}

.space-btwn {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

/* === WIDTH === */

.half {
  width: 50vW;
}
.w-one-hundred {
  width: 100%;
}

.full-width {
  width: 100%;
}

.seventy-five {
  width: 70vW;
}

.seventy-percent {
  width: 75%;
}

.sixty {
  width: 65vW;
}

.fifty-percent {
  width: 50%;
}

.thirty-percent {
  width: 30%;
}

.twenty-five-percent {
  width: 25%;
}

.twenty {
  width: 15vW;
}

.text-width {
  width: 50vW;
}

/* === HEIGHT === */

.full-height {
  height: 100vH;
}

.h-one-hundred {
  height: 100%;
}


.height-150px {
  height: 150px;
}

/* ====== PADDING ====== */

.menu-side-padding {
  padding: 0vH 30px;
}

.side-padding {
  padding: 0vH 2vW;
}

.stnd-padding {
  padding: 10vH 0vW;
}

.text-padding {
  padding: 2vH 1vW;
}

.footer-padding {
  padding: 5vH 0vW;
}

.no-space {
  margin: 0;
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-top-space {
  margin-top: 0;
  padding-top: 0;
}

.tech-padding {
  padding: 0vH 2vW;
  margin: 1vH 0vW;
}

.tech-padding-first {
  padding: 0vH 2vW 0vH 0vW;
  margin: 1vH 0vW;
}

.tech-padding-first-reverse {
  padding: 0vH 0vW 0vH 2vW;
  margin: 1vH 0vW;
}

.icon-padding {
  padding: 1vH 1vW;
}

.icon-padding-first {
  padding: 1vH 1vW 1vH 0vW;
}

.top-padding {
  margin-top: 40vH;
}

.tab-padding {
  padding: 2vH 0vW;
}

/* ====== ANIMATIONS ====== */

.transition-duration {
  transition-property: width;
  transition-duration: 3s;
}

/* ====== HOME ====== */

.ampersand {
  width: 10vW;
}


.left-margin {
  margin-left: 20px;
}

.right-margin {
  margin-right: 20px;
}

.menu-width {
  width: 180px;
}

.left-menu {
  position: fixed;
  left: -210px;
}

.left-menu-triangle {
  margin-top: 20px;
  width: 20px;
  height: 30px;
  transition-duration: 1s;
}

.left-menu-triangle-close {
  right: 0px;
  top: 20px;
  width: 20px;
  height: 30px;
  transform: rotate(180deg);
  opacity: 0%;
  transition-duration: 1s;
}

.right-menu {
  position: fixed;
  right: -210px;
}

.right-menu-triangle {
  margin-top: 20px;
  transform: rotate(180deg);
  width: 20px;
  height: 30px;
}

.right-menu-triangle-close {
  top: 20px;
  left: 0px;
  width: 20px;
  height: 30px;
  opacity: 0%;
}

/* ====== AMPERSAND ====== */

.big-ampersand {
  font-family: 'Nasalization';
  font-size: 400px;
  opacity: 50%;
}

.portrait {
  width: 25vW;
  border-radius: 30px;
}

.half-half-gradient {
  background: linear-gradient(90deg, var(--bright-red) 0%, var(--bright-red) 50%, white 50%, white 100%)
}
/* ====== PORTFOLIO ====== */

.portfolio-image {
  border-radius: 10px;
  top: 0;
  width: 40vW;
  height: 23vW;
  background-size: cover;
  background-repeat: no-repeat;
}



.portfolio-image-overlay {
  background-color: var(--light-blue);
  border-radius: 10px;
  height: 100%;
  opacity: 70%;
  width: 40vW;
  -webkit-animation: portfolioOut .5s forwards;
  -moz-animation: portfolioOut .5s forwards;
  -o-animation: portfolioOut .5s forwards;
  animation: portfolioOut .5s forwards;
}

.portfolio-image-overlay:hover {
  -webkit-animation: portfolio 1s forwards;
  -moz-animation: portfolio 1s forwards;
  -o-animation: portfolio 1s forwards;
  animation: portfolio 1s forwards;
}

.portfolio-text {
  width: 40vW
}

.right {
  right: 0%;
}

.left {
  left: 0%;
}

.icon {
  width: 30px;
}

.icon-footer{
  width: 50px;
}

/* ====== EXPERIENCE ====== */

.tab:hover {
  border-right: 2px solid var(--light-blue)
}

.triangle {
  width: 20px;
  height: 30px;
}

.experience-width {
  width: calc();
}

.experience-height {
  min-height: 50vH;
}

@media screen and (max-width: 800px) {

  /* ====== FONTS ====== */

h1 {
  font-size: 2.5em;
}

.circles {
  padding-left: 4vw !important;
}

.sub-title {
  font-size: 1.25em;
}
/* ====== HEIGHT ====== */

.experience-height  {
  height: 100%;
}

.height-150px {
  height: 100%;
  padding-bottom: 20px;
}

.mobile-h-one-hundred {
  height: 100%;
}

.mobile-100vH {
  height: 100vH;
}

/* ====== WIDTH ====== */

.twenty-five-percent {
  width: 100%;
}

.fifty-percent {
  width: 100%;
}

.seventy-percent {
  width: 100%;
}

.seventy-five {
  width: 75vW;
}

.text-width {
  width: 70vW;
}

.twenty {
  width: 60vW;
}

/* ====== PADDING ====== */

.footer-padding {
  padding: 0vH 40px;
}

.text-padding {
  padding: 1vH 2vW;
}

/* ====== SPACING ====== */

.mobile-column {
  flex-direction: column;
}

.mobile-column-reverse {
  flex-direction: column-reverse;
}

.mobile-flex {
  display: flex;
}

/* ====== POSITION ====== */

.mobile-relative {
  position: relative;
}

/* ====== BORDERS ====== */

.mobile-border-bottom {
  border-right: none;
  border-bottom: 1px solid var(--bright-red)
}

/* ====== HOME ====== */

.ampersand {
    width: 100px;
  }

.mobile-ampersand {
  display: none;
}

/* ====== PROJECTS ====== */

.portfolio-text {
  width: 75vW;
}

.portfolio-image {
  width: 75vW;
  height: 43.125vW;
}

.portfolio-image-overlay {
  display: none;
}

.portfolio-title {
  width: 100%;
}

.mobile-triangle-tab {
  transform: rotate(180deg) !important;
}
/* ====== FOOTER ====== */

.icon-footer{
  width: 30px;
}

.icon-padding {
  padding: 1vH 3vW;
}

.icon-padding-first {
  padding: 1vH 3vW 1vH 0vW;
}

}